<template>
    <div>
        <b-overlay
            :show="false"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card 
                            :title="$t('Moyasar')"
                        >
                            <div class="text-center mb-1">
                                <img src="https://cdn.mhd.sa/asset/images/providers/mada-icon.png" height="30" />
                                <img src="https://cdn.mhd.sa/asset/images/providers/visa-icon.png" height="30" />
                                <img src="https://cdn.mhd.sa/asset/images/providers/mastercard-icon.png" height="30" />
                                <img src="https://cdn.mhd.sa/asset/images/providers/applepay-icon.png" height="30" />
                            </div>
                            <h4 class="text-primary">{{$t('Packages')}}</h4>   
                            <dl class="row">
                                <dt class="col-sm-4 font-weight-bolder">
                                    {{$t('Setup fee')}}
                                </dt>
                                <dd class="col-sm-8">
                                    <span class="text-danger">0 {{$t('SAR')}}</span>
                                </dd>           
                                <dt class="col-sm-4 font-weight-bolder">
                                    {{$t('Monthly fee')}}
                                </dt>
                                <dd class="col-sm-8">
                                    <span class="text-danger">0 {{$t('SAR')}}</span>
                                </dd>           
                                <dt class="col-sm-4 font-weight-bolder">
                                    {{$t('Mada fee')}}
                                </dt>
                                <dd class="col-sm-8">
                                    <span class="text-success">1.65 %</span>
                                </dd>           
                                <dt class="col-sm-4 font-weight-bolder">
                                    {{$t('Credit Card fee (Visa & Mastercard)')}}
                                </dt>
                                <dd class="col-sm-8">
                                    <span class="text-success">2.65 %</span>
                                </dd>           
                                <dt class="col-sm-4 font-weight-bolder">
                                    {{$t('International transaction fee')}}
                                </dt>
                                <dd class="col-sm-8">
                                    <span>3.65 %</span>
                                </dd>           
                                <dt class="col-sm-4 font-weight-bolder">
                                    {{$t('Anti-fraud and risk management fee')}}
                                </dt>
                                <dd class="col-sm-8">
                                    <span>1 {{$t('SAR')}}</span>
                                </dd>           
                            </dl>      
                            <hr>             

                            <template v-if="!isSent">                                
                                <template v-if="!openForm">
                                    <b-button
                                        variant="success"
                                        @click="openForm = true"
                                    >
                                        {{$t('Submit form now')}}
                                    </b-button>
                                </template>
                                <template v-else>
                                    <b-form @submit.prevent="submit">
                                        <h4 class="text-primary">{{$t('Submit form now')}}</h4>  
                                        <small>{{$t('Moyasar team will contact you to activate your account')}} - {{$t('Activation will take about 48 hours')}}</small>                                  
                                        
                                        <b-form-group
                                            :label="$t('Legal entity type')"
                                            class="mt-2"
                                        >
                                            <div class="demo-inline-spacing">
                                                <b-form-radio
                                                    v-model="type"
                                                    name="type"
                                                    value="establishment"
                                                >
                                                    {{$t('Establishment')}}
                                                </b-form-radio>
                                                <b-form-radio
                                                    v-model="type"
                                                    name="type"
                                                    value="company"
                                                >
                                                    {{$t('Company')}}
                                                </b-form-radio>
                                            </div>
                                        </b-form-group>

                                        <b-row class="mt-1">
                                            <!-- Store Name -->
                                            <b-col sm="6" cols="12">
                                                <b-form-group
                                                    :label="$t('Store Name')"
                                                >
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.store_name"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <!-- Store Owner Name -->
                                            <b-col sm="6" cols="12">
                                                <b-form-group
                                                    :label="$t('Store Owner Name')"
                                                >
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.store_owner_name"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            
                                            <!-- Store Url -->
                                            <b-col sm="6" cols="12">
                                                <b-form-group
                                                    :label="$t('Store Url')"
                                                >
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.store_url"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            
                                            <!-- Mobile -->
                                            <b-col sm="6" cols="12">
                                                <b-form-group
                                                    :label="$t('Mobile')"
                                                >
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.mobile"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            
                                            <!-- Email -->
                                            <b-col sm="6" cols="12">
                                                <b-form-group
                                                    :label="$t('Email')"
                                                >
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.email"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <!-- Email -->
                                            <b-col sm="6" cols="12">
                                                <b-form-group
                                                    :label="$t('Store Industry')"
                                                >
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.industry"
                                                        :placeholder="$t('Mobiles And Smart Devices, Perfumes, Others')"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mt-2">
                                            <b-col cols="12" sm="6">
                                                <b-form-group
                                                :label="$t('Commercial Registration Image')"
                                                >
                                                    <b-form-file
                                                        id="cr_image"
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        :browse-text="$t('Browser')"
                                                        :placeholder="$t('Upload')"
                                                        v-model="form.cr_image"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" sm="6">
                                                <b-form-group
                                                :label="$t('Tax certificate image')"
                                                >
                                                    <b-form-file
                                                        id="tax_cert_image"
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        :browse-text="$t('Browser')"
                                                        :placeholder="$t('Upload')"
                                                        v-model="form.tax_cert_image"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" sm="6">
                                                <b-form-group
                                                :label="$t('National Identity Image (front)')"
                                                >
                                                    <b-form-file
                                                        id="id_image_front"
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        :browse-text="$t('Browser')"
                                                        :placeholder="$t('Upload')"
                                                        v-model="form.id_image_front"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" sm="6">
                                                <b-form-group
                                                :label="$t('National Identity Image (back)')"
                                                >
                                                    <b-form-file
                                                        id="id_image_back"
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        :browse-text="$t('Browser')"
                                                        :placeholder="$t('Upload')"
                                                        v-model="form.id_image_back"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            
                                            <!-- Bank Account Card Image -->
                                            <b-col cols="12" sm="6">
                                                <b-form-group
                                                :label="$t('Bank Account Card Image')"
                                                >
                                                    <b-form-file
                                                        id="bank_account_image" 
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        :browse-text="$t('Browser')"
                                                        :placeholder="$t('Upload')"
                                                        v-model="form.bank_account_image"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <!-- National Address Image -->
                                            <b-col cols="12" sm="6">
                                                <b-form-group
                                                :label="$t('National Address Image')"
                                                >
                                                    <b-form-file
                                                        id="national_address_image" 
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        :browse-text="$t('Browser')"
                                                        :placeholder="$t('Upload')"
                                                        v-model="form.national_address_image"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <!-- Memorandum of Association -->
                                            <b-col cols="12" sm="6" v-if="type == 'company'">
                                                <b-form-group
                                                :label="$t('Memorandum of Association Image')"
                                                >
                                                    <b-form-file
                                                        id="memorandum_association_image" 
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        :browse-text="$t('Browser')"
                                                        :placeholder="$t('Upload')"
                                                        v-model="form.memorandum_association_image"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <!-- Partners National Identity Image -->
                                            <b-col cols="12" sm="6" v-if="type == 'company'">
                                                <b-form-group
                                                :label="$t('Partners National Identity Image')"
                                                >
                                                    <b-form-file
                                                        id="partners_identity_image" 
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        :browse-text="$t('Browser')"
                                                        :placeholder="$t('Upload')"
                                                        v-model="form.partners_identity_image"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <!-- submit -->
                                            <b-col cols="12" class="text-right p-0">
                                                <b-button
                                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                type="submit"
                                                variant="primary"
                                                >
                                                    {{$t('Submit Application')}}
                                                    <template v-if="isLoadingSave">
                                                        <b-spinner small />
                                                    </template>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </template>
                            </template>
                            <template v-else>
                                <p class="text-center text-success"><feather-icon icon="CheckCircleIcon" size="50" /></p>
                                <p class="text-center text-success">{{$t('Application Sent Successfully')}}</p>
                                <p class="text-center text-success">{{$t('Moyasar team will contact you soon')}}</p>
                            </template>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
  components: { FeatherIcon },
    directives: {
        Ripple,
    },
    mounted() {
        
    },
    data() {
        return {
            form: {
                store_id: this.$store.state.store.id,
                store_name: this.$store.state.store.name,
                store_owner_name:  this.$store.state.user.name,
                store_url:  this.$store.state.store.url,
                mobile:  this.$store.state.user.mobile,
                email:  this.$store.state.user.email,
            },
            isLoading: true,
            isLoadingSave: false,
            type: 'establishment',
            openForm: false,
            isSent: false,
        }
    },
    methods: {
        submit() {
            let form = new FormData()
            Object.entries(this.form).forEach(([key, value]) => {
                form.append(key, value);
            });

            this.isLoadingSave = true;
            useJwt.post('/payment-provider/moyasar-form/send',form)
            .then((response) => {
                // console.log(response.data)
                this.$root.showToast('success', 'sent', '')

                this.isLoadingSave = false
                this.isSent = true
            })
            .catch(response => {
                this.isLoadingSave = false
                // console.log(response);
            });
        }
    },
}
</script>

<style lang="sass" scoped>
    .demo-inline-spacing > *
        margin-top: 0
</style>
